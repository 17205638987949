function getBrowser() {
  var ua = navigator.userAgent,tem,M = ua.match(/(opera|chrome|safari|firefox|msie|trident|samsungbrowser(?=\/))\/?\s*(\d+)/i) || []
  if(/trident/i.test(M[1])){
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return { name:"IE",version:(tem[1] || "") }
  }
  if(M[1] === "Chrome"){
    tem = ua.match(/\bOPR|Edge\/(\d+)/)
    if(tem != null)   {return { name:"Opera", version:tem[1] }}
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"]
  if((tem = ua.match(/version\/((\d+).(\d+))/i)) != null) {M.splice(1,1,tem[1])}
  return {
    name: M[0],
    version: M[1],
  }
}

function oldBrowserAlert(browser_name) {
  alert(`Your version of ${browser_name} is too old! 😕\n\nPlease update it or use another browser like Chrome or Firefox to use Evalmee. \n\n\n\n Votre version de ${browser_name} est trop ancienne ! 😕\n\nMerci de le mettre à jour ou d'utiliser un autre navigateur à jour comme Chrome ou Firefox pour utiliser Evalmee.`)

}

const browserMinVersion = {
  Chrome: 87,
  Safari: 14,
  Firefox: 85,
  msie: 12,
  Edge: 88,
  SamsungBrowser: null,
}

if (browserMinVersion[getBrowser().name] > parseFloat(getBrowser().version) || browserMinVersion[getBrowser().name] === null) {
  window.location = "#/update_browser"
  oldBrowserAlert(getBrowser().name)
}
