// This is a fix for the router error that occurs when the same route is clicked twice
// https://stackoverflow.com/a/58439497
import VueRouter, { isNavigationFailure, NavigationFailureType } from "vue-router"

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(error => {
    // catch Navigation cancelled error, when a new navigation is triggered before the current one is resolved
    if (isNavigationFailure(error, NavigationFailureType.redirected)) return
    // catch Navigation duplicated error
    if (error.name === "NavigationDuplicated") return
    
    throw error
  })
}

export default VueRouter
